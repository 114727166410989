@import "~@ng-select/ng-select/themes/default.theme.css";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";
body, html {
  height: 100%;
}

body
{
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  overflow-x: hidden;
  background: #f7f9fb;
}
.mat-table
{
  font-family: "Montserrat", sans-serif !important;
}

:root {
  --primary-color: #9c1313;
  --secondary-color: #384735;
  --dark-primary-color: #19222c;
  --dark-secondary-color: #222e3c;
}
ul {
  margin: 0px;
  padding: 0px;
}
a:hover {
  text-decoration: none;
}
a, button {
  transition: 0.3s;
  text-decoration: none;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
p {
  color: #515365;
}
.text-theme-primary
{
  color: var(--primary-color);
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b2b2b2;
}

.btn-default {
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 6px 14px;
  font-size: 14px;
}

.btn-default:hover {
  background: #fff;
  color:#000;
  border-color: var(--primary-color);
}

.btn-border {
  border: none;
  outline: solid 1px var(--primary-color);
  background: #fff;
  color: var(--primary-color);
  outline-offset: -1px;
}
.btn-border:focus {
  outline: solid 1px var(--primary-color) !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus, button:focus {
  box-shadow: none !important;
}
textarea.form-control {
  min-height: 80px;
}
.form-control, .form-select {
  border: 1px solid #e2e2e2;
  color: #000;
  border-radius: 10px;
  min-height: 40px;
  font-size: 14px;
  box-shadow: none !important;
}
.form-control:focus, .form-select:focus {
  box-shadow: none;
  border-color: #000;
}
.content-box label, .custom-modal label {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 13px;
  display: block;
    width: 100%;
}
.page-form-filter label {
  font-size: 13px;
  font-weight: 600;
}
td.table-action-btn button {
  border: none;
  background: transparent;
}

td.table-action-btn a,
td.table-action-btn button {
  margin: 0px 6px;
  font-size: 14px;
}

.table-action-btn .table-action-btn-edit {
  color: #696969;
}

.table-action-btn .table-action-btn-add {
  color: #717171;
}

.table-action-btn .table-action-btn-delete {
  color: #ff0a0a;
}

.table-action-btn .table-action-btn-view {
  color: #000;
}


.widget.box .widget-header .btn {
  font-size: 14px;
}

.sm-form-center {
  max-width: 450px;
  margin: auto;
}

.custom-breadcrum .breadcrumb {
  background: transparent;
  padding: 0px;
  border: none;
  margin: 8px 0px 5px 0px;
}


.form-group>label {
  display: block;
}

.form-radio-check-grp {
  padding: .75rem 1.25rem;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  height: calc(1.4em + 1.4rem + 2px);
  background: #fff;
}

input[type="file"] {
  padding: 8px 10px;
}

img {
  max-width: 100%;
}

.file-upload-preview-items {
  height: 300px;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 #ededed;
  margin: 20px;
  border-radius: 10px;
}

.file-upload-preview-items-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  background: #f44336;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}

.file-upload-preview-items-delete:hover {
  color: #fff;
  background: #ec6058;
}

::-moz-selection {
  background: #4361ee;
  color: #fff;
}

::selection {
  background: #4361ee;
  color: #fff;
}

.page-not-found {
  padding: 50px 15px;
}

.page-not-found-img {
  max-width: 600px;
  margin: auto auto 40px auto;
}

.page-not-found h1 {
  font-size: 55px ;
  font-weight: 800 ;
  color: var(--primary-color);
  margin-bottom: 40px ;
}

.page-not-found p {
  font-size: 20px;
  color: #000;
  line-height: 30px;
}

tr.mat-header-row {
  height: auto !important;
}



.custom-breadcrum .breadcrumb .breadcrumb-item.active {
  color: #000000;
  font-weight: 600;
}

.custom-breadcrum .breadcrumb li {
  font-size: 12px;
}

.ng-select .ng-clear-wrapper {
  width: auto !important;
  top: 2px;
}
.ng-select .ng-clear-wrapper .ng-clear {
  font-size: 24px !important;
  position: relative;
  top: 2px;
}

.ng-select-container {
  height: 40px !important;
  padding: .75rem 0.0rem .75rem 0.4rem;
  line-height: 32px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 0.9em;
  background-color: #e5e5e5;
  border: none;
  border-radius: 5px;
  height: 24px;
  line-height: 22px;
  color: #000;
  font-weight: 600;
}

.ng-select .ng-arrow-wrapper {
  cursor: pointer;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  pointer-events: none;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
}

.switch-custom-d {
  width: auto !important;
  height: auto !important;
}

.switch-t-dactive {
  position: relative;
  right: 68px;
}

.font-w-800 {
  font-weight: 800;
}

.font-w-200 {
  font-weight: 200;
}

.p-10 {
  padding: 10px;
}

.table-data-status-round span {
  height: 15px;
  width: 15px;
  background: transparent;
  display: inline-block;
  border-radius: 100%;
  border: solid 2px;
  margin-right: 5px;
}

.table-data-name span {
  background: #f4dcff;
  height: 40px;
  width: 40px;
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
  color: #000;
  text-transform: capitalize;
}

table tbody tr:nth-of-type(odd) .table-data-name span {
  background: #ffebd6;
}

.table-borderless {
  border-collapse: unset;
}

.table-font-17 td {
  font-size: 17px !important;
}

.table-data-info strong {
  color: #000;
}

.table-data-info tr td {
  padding: 2px 0px;
}

.border-radius {
  border-radius: 10px;
}

.small-media-items {
  height: auto;
  max-width: 55px;
  border-radius: 5px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 10px 15px 0px;
  border: solid 1px #f1f1f1;
}

/* .small-media-items-img img {
  max-width: 80px;
  max-height: 80px;
} */

.small-media-items-action {
  position: absolute;
  bottom: 5px;
  background: rgba(0, 0, 0, 0.5);
  padding: 0px 4px;
  border-radius: 8px;
}

.small-media-items-action a {
  color: #fff;
  margin: 0px 4px;
  font-size: 12px;
}

.modal-btn-with-no-header {
  position: absolute;
  top: -15px;
  right: -12px;
  background: #ff5253 !important;
  height: 32px;
  width: 32px;
  z-index: 9999999999;
  opacity: 10;
  border-radius: 100%;
  line-height: 34px;
  text-shadow: none;
  color: #fff !important;
  font-size: 17px;
  opacity: 9 !important;
}

.modal-btn-with-no-header:hover {
  background: var(--primary-color) !important;
}

.show-gallery .modal-dialog {
  max-width: max-content;
}

.modal-doc-show img {
  max-width: 700px;
}

.modal-backdrop.show {
  z-index: 999;
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
  background-color: #000;
  opacity: 0.7;
}

.modal-open .modal {
  padding: 0px !important;
  z-index: 9999999;
}

.page-profile {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 20px #e5e5e5;
}

.table-v-align-top td {
  vertical-align: top !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
}

.btn-upload {
  font-size: 20px;
  border: none;
  background: transparent;
  color: #000;
  cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: 9;
  cursor: pointer;
}

.custom-card-1 {
  box-shadow: 0 0 20px #f1f1f1;
  border-radius: 12px;
  overflow: hidden;
}

.custom-card-1-header {
  background: #f7f9fb;
  padding: 6px 10px;
  font-size: 20px;
  color: #000;
}

.custom-card-1-body {
  padding: 10px;
  color: #000;
  font-weight: 600;
}

.custom-card-1-header i {
  margin-right: 5px;
  opacity: 0.2;
}

.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
}


.table-view-btn {
  text-align: right;
}

.btn-change-view {
  background: #e7e7e7;
  border: none;
  padding: 9px 11px;
  border-radius: 8px;
  font-size: 18px;
}

.btn-change-view.active,
.btn-change-view:hover {
  background: #20488b;
  color: #fff;
}

.dropdown.card-action-box {
  position: absolute;
  right: 20px;
  top: 20px;
}

.dropdown.card-action-box>button {
  background: transparent;
  border: none;
  padding: 0px;
}

.dropdown.card-action-box ul.dropdown-menu li a {
  padding: 5px 10px;
  display: block;
  font-size: 14px;
  letter-spacing: normal;
  color: #000;
}

.dropdown.card-action-box ul.dropdown-menu li a i {
  margin-right: 5px;
}

.dropdown.card-action-box ul.dropdown-menu {
  box-shadow: 0 0 20px #e5efff !important;
  border: none !important;
  padding: 0px !important;
}

.dropdown.card-action-box ul.dropdown-menu li a:hover {
  background: #fff0f0;
}

.page-profile-w-edit {
  max-width: 200px;
  margin: auto;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.text-black {
  color: #000;
}

.font-w-600 {
  font-weight: 600;
}

.page-mid-data-table-center {
  max-width: 900px;
  margin: auto;
  box-shadow: 0 0 20px #c9d8f1;
  border-radius: 15px;
  overflow: hidden;
}

.table-no-border tr,
.table-no-border td {
  border: none !important;
}

.big-checkbox span.new-control-indicator {
  height: 30px !important;
  width: 30px !important;
}

.big-checkbox {
  margin: 0px;
  height: 30px;
  width: 30px;
}

.big-checkbox span.new-control-indicator:after {
  margin-left: -2px !important;
  margin-top: -6px !important;
  width: 6px !important;
  height: 10px !important;
}

.btn-upload-border i {
  margin-right: 5px;
  font-size: 15px;
}
.btn-upload-border {
  background: #fff;
  border: dotted 2px #e9e9e9;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-transform: uppercase;
  min-height: 39px;
}

.file-upload-details {
  background: transparent;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.file-upload-details span {
  color: #7c7c7c;
  font-size: 13px;
}
.file-upload-details a {
  color: #000000;
}
.file-upload-details .dz-filename i {
  margin-right: 6px;
  font-size: 18px;
  position: relative;
  top: 2px;
  color: #7c7c7c;
}
.file-upload-details .dz-details {
  flex: 100%;
}

.file-upload-details .file-upload-details-remove {
  font-size: 16px;
  cursor: pointer;
  flex: 0 0 20px;
  text-align: center;
}

.file-upload-details .dz-details .dz-filename {
  color: #000;
  font-size: 15px;
}

.is-invalid .btn-upload-border {
  border-color: red;
  color: red;
}

.file-upload-details .dz-details .dz-filename {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
}

.bank-master-add-branch-btn {
  font-size: 16px;
}
.custom-modal .modal-header h5 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
.custom-modal .modal-header .close {
  color: #fff;
  text-shadow: none;
  opacity: 9;
  font-size: 35px;
  line-height: 24px;
}

.custom-modal .modal-header {
  background: #f7f9fb;
  padding: 6px 12px;
  border: none;
  margin: 10px;
  border-radius: 10px;
  margin-bottom: 0px;
}

.modal-content {
  border-radius: 25px;
  overflow: hidden;
  border: none;
}

.b-h-form {
  min-height: 44px;
}

.file-upload-details .file-upload-details-remove {
  margin-left: 10px;
}

.table-responsive {
  box-shadow: 0 3px 10px rgba(10,37,64,0.05);
  padding: 5px;
  background: #fff;
  padding-bottom: 20px;
}

.rounded-tab-icon .table-responsive {
  box-shadow: none;
}

.table-list-img {
  height: 35px;
  width: 35px;
  background: transparent;
  overflow: hidden;
}

.table-list-img img {
  max-width: 35px;
  max-height: 35px;
  border-radius: 4px;
}

.table>tbody tr:last-child {
  border: none;
}

.paginate_button.page-item i {
  font-size: 20px;
}

table.table .switch.s-icons .slider {
  width: 40px;
  height: 20px;
}

table.table .switch.s-icons input:checked+.slider:before {
  transform: translateX(19px);
  bottom: 0px;
}

table.table .switch.s-icons .slider:before {
  height: 17px;
  width: 17px;
}

table.table .switch.s-outline[class*="s-outline-"] .slider:before {
  bottom: 0px;
  left: -1px;
}

element.style {
  transform: none;
  /* padding: 0px; */
}

.mat-custom-modal .mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
  border-radius: 24px !important;
  background: transparent;
}

.default-section-img {
  max-width: 400px;
  margin: auto auto 20px auto;
}

.default-section-info h1 {
  text-transform: capitalize;
  font-size: 34px;
  color: var(--primary-color);
  margin: auto;
  font-weight: 500;
  margin-bottom: 20px;
}

.default-section-info p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px auto 30px auto;
}

.default-section-info .btn {
  font-size: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
  background-color: rgba(0, 0, 0, 0.6) !important;
  z-index: 99999999;
}

.cdk-overlay-dark-backdrop {
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
  background-color: rgba(0, 0, 0, 0.6) !important;
  z-index: 999 !important;
}

.swal2-container.swal2-center>.swal2-popup {
  border-radius: 30px;
}

.swal2-styled.swal2-confirm {
  background: var(--primary-color) !important;
}

.swal2-actions button {
  padding: 6px 15px;
}
.swal2-actions .swal2-styled.swal2-cancel {
  background: #fff;
  color: var(--primary-color);
  outline: solid 1px var(--primary-color);
  outline-offset: -1px;
}
.swal2-actions .swal2-styled.swal2-cancel:hover {
  background: transparent;
}
.swal2-html-container {
  margin-top: 0px !important;
}

.swal2-title {
  font-size: 30px !important;
  margin-bottom: 10px !important;
}

.swal2-icon {
  margin-top: 25px !important;
}

.swal2-popup {
  width: 100% !important;
  max-width: 430px !important;
}

app-header {
  z-index: 999999;
}

.cdk-overlay-container {
  z-index: 999 !important;
}


[data-title] {
  position: relative;
}

table tr:last-child td [data-title]:hover::before {
  bottom: -25px;
}
[data-title]:hover::before {
  content: attr(data-title);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  z-index: 99999999999;
}

[data-title]:hover::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.ng-select .ng-select-container {
  border-color: #e2e2e2;
  border-radius: 10px;
}

.big-size-media-items {
  height: 200px;
  width: 200px;
  flex-basis: 200px;
}

.big-size-media-items img {
  max-width: 200px;
  max-height: 200px;
}

i.fas.fa-times {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
}

.no-record-found {
  padding: 50px 10px;
}

.no-record-found p {
  font-size: 30px;
  color: black;
}

.btn-search-text-remove {
  position: absolute;
  right: 35px;
  top: 6px;
  font-size: 24px;
}

.btn-search-text-remove i {
  color: #df0303 !important;
}

.product-details-right-info h2 {
  font-size: 22px;
  margin-bottom: 8px;
  color: #000;
  line-height: 28px;
}

.product-details-right-info h3 {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
}
.product-details-right-info h3 span {
  color: #585656;
  font-weight: 400;
  font-size: 16px;
}
.product-detail-price h5 {
  font-size: 16px;
}

.product-details-right-info {
  font-size: 13px;
}

.product-details-right-info strong {
  color: #000;
}

.font-16 {
  font-size: 16px;
}

.table-font-15 td {
  font-size: 15px !important;
}

.product-detail-img-tab .nav-tabs .nav-link {
  padding: 0px;
  border: solid 1px #ebebeb ;
  border-radius: 5px;
  overflow: hidden;
}

.product-detail-img-tab .nav-tabs .nav-link.active {
  border-color: var(--primary-color);
}

.product-detail-img-tab .nav-item {
  flex: 0 0 50px;
  max-width: 50px;
  margin: 5px;
  text-align: center;
}
.product-detail-img-tab {
  position: sticky;
  top: 60px;
}
.product-detail-img-tab .nav
{
  flex-wrap: nowrap;
  overflow: auto;
}

.product-detail-img-tab .nav-item img {
  max-height: 80px;
}
.product-detail-img-tab .tab-content .tab-pane {
  background: #fff;
  border-radius: 5px;
}

.product-detail-img-tab .tab-content .tab-pane img {
  max-height: 750px;
  border-radius: 5px;
}

.product-details-right-info .table-responsive {
  box-shadow: none;
  padding: 0px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 12px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 12px !important;
}

.label-info {
  font-weight: 500;
  color: #6c757d;
  font-size: 12px;
}
.mt--30 {
  margin-top: -30px;
}

.mt--25 {
  margin-top: -25px;
}

.model-stock-create .modal-content {
  overflow: initial;
}
/*
.model-stock-create .modal-header {
  border-radius: 25px 25px 0px 0px;
} */

.unit-pro-price {
  position: relative;
}

.unit-pro-price button {
  position: absolute;
  right: 13px;
  top: 28px;
  padding: 12px 7px;
  border: none;
  font-weight: 600;
  color: #000;
  background: #e8e9f1;
}

.unit-pro-price button:hover {
  background: #e7e7e7;
}

.fieldset-header-btn {
  border: solid 1px var(--primary-color);
  background: var(--primary-color);
  color: #fff;
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 12px;
}

.fieldset-header-btn:hover {
  background: #fff;
  color: #000;
}
.angular-editor ul {
  padding-left: 15px;
}
.angular-editor-textarea {
  white-space: normal;
  word-break: break-all;
  border: 1px solid #e2e2e2 !important;
  max-height: 500px;
}

.angular-editor-toolbar {
  background-color: #fbfbfb  !important;
  border: none !important;
  padding: 7px 10px 3px 10px !important;
}
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(6), 
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(7), 
.angular-editor-toolbar .angular-editor-toolbar-set:nth-child(10)
{
  display: none !important;
}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  box-shadow: 0 0 20px #e3e3e3;
  margin: 0px 2px;
}
.angular-editor-textarea.disabled {
  background: #e9ecef !important;
}
.cmis-page-create-from {
  flex: 1 1 60%;
  padding: 0px;
}

.cmis-page-preview {
  flex: 1 1 40%;
  padding-left: 25px;
}

.cmis-page-preview-body {
  max-height: calc(100vh - 155px);
  overflow: auto;
}
.cmis-page-preview-body::-webkit-scrollbar {
  width: 2px;
}

.cmis-page-preview-body::-webkit-scrollbar-track {
  margin: 15px 0px;
}

.cmis-page-preview-body::-webkit-scrollbar-thumb {
  background: #ccc;
  height: 50px;
}

.cmis-page-preview-header {
  font-size: 18px;
  color: var(--primary-color);
  margin-bottom: 8px;
  font-weight: 600;
}

.angular-fix-min-height .angular-editor-textarea {
  min-height: 200px !important;
}

.cmis-page-preview-items .cmis-page-preview-items-action-info h3 {
  margin: 0px 0px 5px 0px;
  color: #000;
  font-weight: 600;
  font-size: 18px;
}

.cmis-page-preview-items .cmis-page-preview-items-action-info p {
  line-height: 22px;
  font-size: 14px;
}

.cmis-page-preview-items .cmis-page-preview-items-action-info p p {
  margin: 0px;
}

.cmis-page-preview-items-action .cmis-page-preview-items-action-btn {
  font-size: 15px;
  margin-right: 10px;
}

.cmis-page-preview .cmis-page-preview-body .cmis-page-preview-items {
  background: transparent;
  padding: 10px;
  border-bottom: solid 1px #f7f7f7;
  margin: 8px 0px;
}

.cmis-page-preview .cmis-page-preview-body .cmis-page-preview-items:last-child {
  margin: 0px;
}

.breadcrum-action-btn {
  font-size: 18px;
}

.checkbox-card {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
}
.checkbox-card label {
  padding-left: 7px;
  position: relative;
  top: 2px;
  font-weight: 500;
  text-transform: capitalize;
}
.checkbox-card .form-check-input {
  height: 16px;
  width: 16px;
  margin-left: 0px !important;
  margin: 0px;
}
.form-check-input:focus
{
  box-shadow: none;
}
.custom-control-label::before,
.custom-control-label::after {
  width: 22px;
  height: 22px;
  top: 0px;
  left: -10px;
}

.custom-control-input:checked~.custom-control-label::before {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:checked {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.custom-control-label {
  padding-left: 20px;
  color: #000;
}

.gen-details-info h2 {
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 5px;
}
.single-detail-img {
  background: transparent;
  text-align: center;
  flex: 1 1 33.33%;
}

.single-detail-img img {
  max-height: 500px;
  margin: auto;
  border-radius: 10px;
}
.gen-details-info {
  flex: 1 1 66.66666667%;
}
.gen-details-info br {
  display: none;
}
.gen-details-info p {
  font-size: 13px;
}
.gen-details-info p ul {
  padding: 0 0 0 15px;
  margin-bottom: 20px;
}
.gen-details-info p a
{
  font-weight: 500;
  color: var(--primary-color);
  text-decoration: underline;
  text-transform: capitalize;
}
.border-round {
  border-radius: 30px;
}

.widget.box .widget-header .row>* {
  width: auto;
}


.swal2-container .swal2-popup {
  width: 100% !important;
  max-width: 320px !important;
  border-radius: 20px !important;
  padding-bottom: 12px !important;
}

.swal2-container .swal2-popup .swal2-icon {
  /* height: 50px !important;
width: 50px !important; */
  border: solid 1px !important;
  margin-top: 15px !important;
  border-color: #c7c7c7 !important;
  color: #000 !important;
}

.swal2-container .swal2-popup .swal2-icon .swal2-icon-content {
  font-size: 40px !important;
}

.swal2-container .swal2-popup .swal2-title {
  padding-top: 10px !important;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 25px !important;
}

.swal2-container .swal2-html-container {
  font-size: 15px !important;
}

.swal2-container .swal2-actions {
  margin-top: 15px !important;
}

.mat-table {
  box-shadow: none !important;
}

.mat-paginator-container {
  min-height: auto !important;
}

body .toast-container .ngx-toastr {
  background-color: #fff;
  color: #000;
  border-radius: 15px;
  box-shadow: 0 0 20px #c9c9c9;
  border: solid 2px transparent;
  padding: 12px 15px 12px 60px;
  background-size: 30px !important;
}

body .toast-container .ngx-toastr:hover {
  box-shadow: 0 0 20px #b3b3b3;
}

body .toast-success {
  border-color: #51A351 !important;
  background-image: url(../images//check.svg) !important;
}

body .toast-error, body .toast-info {
  border-color: #BD362F !important;
  background-image: url(../images//error.svg) !important;
}

body .toast-warning {
  border-color: #F89406 !important;
  background-image: url(../images//warning.svg) !important;
}

body .toast-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
}

body .toast-close-button {
  color: #000;
  text-shadow: none;
  top: -15px;
  left: 0px;
  font-size: 30px;
  font-weight: 500;
}

.sidebarCollapse {
  font-size: 28px;
  color: #000;
  margin-left: 12px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.page-header-title {
  font-size: 23px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.page-header-action {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.page-header-action .form-control, .page-header-action .form-select, .offcanvas .offcanvas-header select.form-select  {
  font-size: 13px;
  min-height: 36px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.page-header-action .form-control:focus, .page-header-action .form-select:focus {
  border-color: #000;
}
.page-header-action-items {
  background: #fff;
  border: 1px solid #e3e3e3;
  font-size: 17px;
  margin: 0px 5px;
  cursor: pointer;
  color: #000;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.page-header-action-items:hover {
  background: #fff;
}
/* .page-header-action-items .fa-regular.fa-plus {
  border: solid 2px #1a1870;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
} */

.page-header-action-search {
  margin-left: 15px;
  position: relative;
  min-width: 310px;
}

.page-header-action-search .btn-filter-search {
  position: absolute;
  background: transparent;
  border: none;
  top: 6px;
  right: 10px;
  font-size: 17px;
  color: #000;
}
.page-header-action-search .btn-filter-search i {
  color: #000;
}
.page-header-action-search input {
  border-color: #ffffff;
  min-height: 38px;
  box-shadow: 0px 1px 4px #d6dbf3;
  border-radius: 6px;
}

input[type="search"]:focus {
  padding-right: 30px;
}
.angular-editor-textarea:focus-visible {
  outline: none;
}
.content-box {
  /* padding: 15px; */
  /* box-shadow: 0 3px 10px rgba(10, 37, 64, 0.05); */
  height: 100%;
}

.mat-paginator {
  background: transparent !important;
}

fieldset.border {
  background: #fff;
}
.pagination {
  justify-content: end;
  margin-top: 5px;
}

.custom-mat-table {
  width: 100%;
}
.custom-mat-table thead th {
  background: #f4f6f9;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 5px;
  border: none;
}

.custom-mat-table th, .custom-mat-table td {
  padding: 5px !important;
  text-transform: capitalize;
}

.custom-mat-table td.mat-cell, .custom-mat-table td {
  border-bottom: solid 1px #f7f9fb;
  font-size: 12px;
  font-weight: 500;
}
.custom-mat-table td.mat-cell p, .custom-mat-table td p {
  margin: 0px;
}
.custom-mat-table tr:last-child td {
  border: none;
}
table.custom-mat-table tbody tr:nth-child(even) {
  background: #fbfbfb;
}

table.custom-mat-table.table-header-light th {
  background: #f4f6f9;
  color: #000;
}
.accordion-button:not(.collapsed) {
  background: #f4f6f9;
}

.theme-change-btn-grp a {
  height: 20px;
  width: 20px;
  background: #fff;
  margin: 0 4px;
  border-radius: 100%;
  cursor: pointer;
}
.theme-change-btn-grp a.btn-theme-default {
  background: #e8e8e8;
}
.theme-change-btn-grp a.btn-theme-dark {
  background: #f7b900;
}
.theme-change-btn-grp a.btn-theme-primary {
  background: #4361ee;
}
.theme-change-btn-grp a.btn-theme-green {
  background: #24695c;
}
footer {
  text-align: center;
  margin-left: 265px;
  font-size: 12px;
  padding: 15px 10px;
  transition: .6s;
}
footer p a {
  color: #000;
  font-weight: 600;
}
header {
  background: #fff;
  box-shadow: 0px 0px 10px #dbe0f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 50px;
}
.app-logo img {
  max-height: 46px;
}
.sidebar {
  position: fixed;
  /* background: rgb(67, 64, 187);
  background: linear-gradient(0deg, rgba(67, 64, 187, 1) 0%, rgba(26, 24, 112, 1) 100%); */
  background: #244856;
  height: 100%;
  width: 205px;
  margin-top: 50px;
  transform: none;
  transition: .6s;
  z-index: 999;
  padding: 10px;
  overflow: auto;
}
body.sidebar-open .sidebar {
  transform: translatex(-260px);
}
.container-with-sidebar {
  margin-left: 200px;
  padding: 65px 15px 25px 40px;
  transform: none;
  transition: .6s;
}
body.sidebar-open .container-with-sidebar {
  margin-left: 0px;
  padding-left: 15px;
}
body.sidebar-open footer {
  margin-left: 0px;
}
.sidebar-menu-btn {
  font-size: 25px;
  margin-left: 64px;
  color: #000;
}
.sidebar-menu-btn:hover {
  color: #525252;
}
body.sidebar-open .sidebar-menu-btn i:before
{
  content: "";
}
.sidebar .sidebar-item .sidebar-item-button {
  background: transparent;
  color: #fff;
  padding: 8px 10px;
  box-shadow: none !important;
  border: none;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -1px;
  border-radius: 10px;
  margin: 3px 0px;
  position: relative;
}
.sidebar .sidebar-item .sidebar-item-button i {
  margin-right: 8px;
  font-size: 20px;
}

.sidebar .sidebar-item.menu-active .sidebar-item-button, .sidebar .sidebar-item .sidebar-item-button:hover {
  background: rgba(255,255,255,0.2);
}
ul.sidebar-submenu .sub-menu-active a {
  font-weight: 700;
  color: #ffffff;
  opacity: 1;
}
.arrow-none::after { display: none !important;}
.sidebar-item-button::after {
  font-family: bootstrap-icons;
  content: "\f282";
  display: inline-block;
  flex-shrink: 0;
  margin-left: auto;
}
.sidebar-item-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}
ul.sidebar-submenu {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.master-items ul.sidebar-submenu a {
  font-size: 12px;
}
ul.sidebar-submenu a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  /* display: flex; */
  padding: 5px 5px 5px 20px;
  align-items: center;
  opacity: 0.9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-transform: capitalize;
}
ul.sidebar-submenu a i {
  margin-right: 5px;
  min-width: 15px;
  text-align: center;
}
.btn-close:focus, .btn-close
{
  box-shadow: none;
}
/* .btn-close
{
  background: transparent;
} */
.btn-close span {
  color: #fff;
  font-size: 40px;
  line-height: 8px;
  font-weight: 100;
}
.flex-auto {
  flex: auto;
}
.header-r-side-btn>ul {
  list-style: none;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
}
.header-r-side-btn>ul>li {
position: relative;
}
.header-r-side-btn>ul>li>a {
  font-size: 20px;
  margin-left: 15px;
  color: #000;
}

/* .header-dropdown a {
  padding: 5px 10px;
  color: #000;
  display: block;
} */
.gen-entry-flags span.btn-sm {
  border-radius: 50px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
}
.page-login-register-right-form button.btn {
  font-size: 22px;
  padding: 6px 12px;
  font-weight: 500;
}
.theme-btn
{
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.theme-btn:hover
{
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}


/* LOGIN START */

section.page-login-register {
  background: url(../images/client/gulmohar/gulmohar_login_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.page-login-register-right-form {
  box-shadow: none;
  padding: 25px;
  border-radius: 10px;
  background: #fff;
  max-width: 500px;
  width: 100%;
  z-index: 1;
}

.page-login-register-right {
  position: relative;
}

.page-login-register-right:before {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50% 0 0 0/100% 0 0 0;
  transform: scaleX(1.5);
}

.login-register-logo {
  display: inline-block;
  padding: 15px 5px;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 20px #fff;
  text-transform: capitalize;
}

.login-register-logo img {
  max-height: 65px;
}

.page-login-register-right-form h2 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 28px;
  color: #000 !important;
  font-weight: 500;
}
.page-login-register-right-form h2 span {
color: var(--primary-color);
}

.form-floating>.form-control, .form-floating>.form-select {
  height: calc(3rem + 2px);
  line-height: normal;
}
.form-floating>label {
  padding: 0.7rem 0.75rem;
  font-size: 14px;
}
.pas-eye {
  position: absolute;
  right: 12px;
  top: 13px;
  opacity: 0.5;
}

.page-login-register-right-form .icon-login-form {
  font-size: 18px;
}
.form-floating>.form-control:not(:placeholder-shown)~label i {
  font-size: 16px;
}

/* LOGIN END */

/* Theme Start */
.theme-dark .sidebar {
  background: #000;
}
.theme-dark .custom-mat-table thead th {
  background: #ffc600;
  color: #000;
}
.theme-primary .sidebar {
  background: #0f259d;
}
.theme-primary .custom-mat-table thead th {
  background: #0f259d;
  color: #fff;
}
.theme-green .sidebar {
  background: #24695c;
}
.theme-green .custom-mat-table thead th {
  background: #795548;
  color: #fff;
}
/* Theme End */
fieldset legend {
  font-size: 22px;
}

.static-form-control {
  border: 1px solid #e2e2e2;
  height: 40px;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
}

.prolist-discount {
  font-size: 12px;
  color: #ff0a0a;
  font-weight: 500;
}
.prolist-price {
  line-height: 16px;
}
.prolist-price .d-flex {
  font-weight: 500;
  color: #000;
}
.dashboard-count-card-items:hover {
  color: #000;
  /* box-shadow: 0 4px 6px #c6cded; */
}
.dashboard-count-card-items {
  /* background: #fff; */
  padding: 10px;
  border-radius: 15px;
  /* box-shadow: 0 2px 6px #d9dff7; */
  color: #000;
}
.dashboard-count-card-items .dashboard-count-card-items-icon {
  margin-right: 12px;
  line-height: 0;
  min-height: 50px;
  min-width: 50px;
  background: var(--secondary-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.dashboard-count-cards .col-sm-6:nth-child(1) .dashboard-count-card-items .dashboard-count-card-items-icon {
  background: #244856;
}
.dashboard-count-cards .col-sm-6:nth-child(2) .dashboard-count-card-items .dashboard-count-card-items-icon {
  background: #e74833;
}
.dashboard-count-cards .col-sm-6:nth-child(3) .dashboard-count-card-items .dashboard-count-card-items-icon {
  background: #10a4b0;
}
.dashboard-count-cards .col-sm-6:nth-child(4) .dashboard-count-card-items .dashboard-count-card-items-icon {
  background: #efb11e;
}
.dashboard-count-card-items .dashboard-count-card-items-icon i {
  font-size: 25px;
}
.dashboard-count-card-items .dashboard-count-card-items-info {
  flex: auto;
}
.dashboard-count-card-items .dashboard-count-card-items-info h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 35px;
}
.dashboard-count-card-items .dashboard-count-card-items-info p {
  font-size: 15px;
}
/* .my-swal {
  z-index: 999 !important;
}
.swal2-container {
  z-index: 999 !important;
} */

.pagesidebar-large {

  max-width: 85%;
}
.offcanvas
{
  width: 100%;
  overflow: auto;
  background: #fff;
}
.offcanvas .offcanvas-title, .offcanvas .offcanvas-title p {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}
.offcanvas .offcanvas-header i.fa-eye {
  position: relative;
  top: 0px;
}
.offcanvas .offcanvas-header .page-header-action-items {
  font-size: 16px;
  height: 30px;
  width: 30px;
  margin-bottom: 0px;
}
.offcanvas .offcanvas-header .btn-close {
  background-size: 16px;
  margin-left: 8px;
}
.offcanvas .offcanvas-body {
  overflow-y: initial;
  padding-top: 2px;
}
.offcanvas .offcanvas-body .content-box {
  border-radius: 8px;
}
.offcanvas .offcanvas-header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9999;
  /* box-shadow: 0 0 20px #e7e7e7; */
  /* border-bottom: solid 1px #f3f3f3; */
  padding: 10px 15px;
}
.offcanvas .offcanvas-header select.form-select {
  min-height: auto;
}
body.modal-open .offcanvas:before {
  background: rgba(0,0,0,0.7);
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  bottom: 0;
  margin-left: -1px;
}
.fa-eye:before {
  content: "";
  font-weight: 300;
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display,inline-block);
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    font-style: normal;
    line-height: 1;
}

.mat-sort-header-arrow {
  color: #757575 !important;
}
.table-list-highlited {
  font-weight: 800;
  background: #e2e2ff;
}
.order-detail-header-items {
  border-right: dotted 1px #dbdbdb;
  padding: 0px 22px;
}
.order-detail-header .order-detail-header-items strong, .order-detail-header .order-detail-header-items p {
  font-size: 13px;
}
.order-detail-header .order-detail-header-items h6 {
  font-size: 15px;
}
.order-detail-header-items h6 {
  margin-bottom: 5px;
}
.order-detail-header .order-detail-header-items:first-child {
  padding-left: 10px;
}
.order-detail-header .order-detail-header-items:last-child {
  border: none;
  flex: auto;
}
span.login-name {
  font-size: 14px;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-right: 8px;
}
.header-r-side-btn>ul>li i.fa-light.fa-angle-down {
  position: relative;
  top: 1px;
}
.login-username-img
{
  background: #f1f1f1;
  border-radius: 100%;
  overflow: hidden;
  height: 25px;
  width: 25px;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.login-username-img img {
  max-width: 25px;
  max-height: 25px;
}
.tab-btns {
  white-space: nowrap;
  overflow: auto;
}
.tab-btns-items {
  border: solid 1px #e6eaef;
  padding: 3px 10px;
  font-size: 14px;
  color: #000;
  margin: 0px 6px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 500;
  position: relative;
}
.tab-btns-items I {
  margin-right: 2px;
  font-size: 16px;
}
.tab-btns-items.active {
  background: #fff;
  color: var(--primary-color);
  /* border-color: var(--primary-color); */
}
.tab-btns-items:hover
{
  color: var(--primary-color);
}
fieldset .fieldset-header legend {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 18px;
  border-radius: 20px;
}
.accordion .accordion-item {
  border: none;
  box-shadow: 0 0 15px #e9e9e9;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
  color: black;
}
.accordion-button {
  font-size: 20px;
  padding: 12px 15px;
  border-radius: 10px !important;
  font-weight: 500;
}
.accordion-body {
  padding: 15px 5px 5px 5px;
}
table.custom-mat-table.table-header-light th:first-child {
  border-radius: 8px 0px 0px 8px;
}
table.custom-mat-table.table-header-light th:last-child {
  border-radius: 0px 8px 8px 0px;
}
.btn-export {
  height: 30px;
  background: transparent;
  box-shadow: 0px 5px 15px #d9e0ff;
  border-radius: 30px;
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}
.btn-export:hover {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.border-box {
  border: solid 1px #e3e2ff;
  border-radius: 15px;
  background: #fff;
  padding: 15px;
  position: relative;
  padding-top: 22px;
  margin-top: 35px;
}
.border-box-title {
  position: absolute;
  font-size: 20px;
  top: -18px;
  background: #fff;
  border: solid 1px #e3e2ff;
  padding: 0px 20px;
  border-radius: 30px;
  left: 25px;
  color: var(--primary-color);
}
app-file-selector.is-invalid + .invalid-feedback{
  margin-top: -10px;
}
/* .small-media-items .small-media-items-img {
  font-size: 40px;
} */
.e-docket-modal .small-media-items {
  margin: 0px !important;
}
pre {
  font-family: "Montserrat", sans-serif;
}
.swal2-container .swal2-html-container pre {
  margin: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #dc3545;
}
.order-detail-short-bill p {
  margin: 0px;
  font-size: 14px;
  line-height: normal;
}
.order-detail-btn-left a, .order-detail-btn-left button {
  color: #008000;
  border: solid 1px #008000;
  padding: 3px 10px;
  border-radius: 20px;
  margin-bottom: 5px;
  display: block;
  opacity: 0.9;
  min-width: 130px;
  text-align: center;
  position: relative;
  margin-right: 10px;
}
.order-detail-btn-left a.active:hover, .order-detail-btn-left button.active:hover
{
border-color: var(--secondary-color);
color: var(--secondary-color);
}
.order-detail-btn-left a:before, .order-detail-btn-left button:before {
  position: absolute;
  content: "";
  font-family: "Font Awesome 6 Pro";
  right: -3px;
  top: -7px;
  font-weight: 900;
}
.order-detail-btn-left a.active, .order-detail-btn-left button.active {
  background: var(--primary-color);
  color: #fff;
  border: solid 1px var(--primary-color);
  opacity: 1;
  animation: btn-zoom-in-zoom-out 2s ease infinite;
}
.order-detail-btn-left a.active:hover, .order-detail-btn-left button.active:hover {
  background: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.order-detail-btn-left a.active:before, .order-detail-btn-left button.active::before
{
  display: none;
}
.order-detail-btn-left a i, .order-detail-btn-left button i {
  font-size: 16px;
}

.order-detail-btn-right .btn-circle {
  height: 30px;
  background: transparent;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}
.order-detail-btn-right {
  display: flex;
}
.order-detail-btn-right [data-title]:hover::before
{
  left: 95%;
  transform: translateX(-95%);
}
.order-detail-btn-left .order-detail-btn-pending-action {
  border-color: #ebebeb;
  color: #6c757d;
}
.order-detail-btn-left .order-detail-btn-pending-action:before
{
  display: none;
}

@keyframes btn-zoom-in-zoom-out {
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

tr.mat-row, tr.mat-footer-row {
  height: auto !important;
}
.sidebar-height {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}
.sidebar-footer p {
  color: #fff;
  font-size: 10px;
  margin: 0px;
}
.sidebar-footer p a {
  color: #fff;
  font-weight: 500;
  opacity: 0.6;
}
.sidebar-footer {
  margin-top: auto;
  padding-bottom: 0px;
}
.sidebar-footer strong {
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  padding: 0px 15px;
}
.sidebar-footer .sidebar-item .sidebar-item-button {
  font-size: 13px;
  font-weight: 500;
}
.modal-close {
  color: #000;
  font-size: 22px;
  line-height: 0;
  background: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
}
.header-dropdown {
  background: #fff;
  box-shadow: 0px 0px 10px #dbe0f3;
  border: none;
  list-style: none;
  min-width: 320px;
  inset: 15px auto auto auto !important;
  padding: 15px;
}
.header-dropdown-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px #f7f9fb;
  padding-bottom: 15px;
}
.header-dropdown-head-img {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background: #f1f1f1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.header-dropdown-head-img img {max-width: 70px;}
.header-dropdown-head-info h4 {
  margin: 0px;
  font-size: 18px;
}
.header-dropdown-head-info p {
  margin: 0px;
  color: #656565;
  font-weight: 300;
  font-size: 13px;
}
.header-dropdown-body {
  padding: 15px 0px;
}
.header-dropdown-body ul {
  list-style: none;
}
.header-dropdown-body ul li a {
  display: block;
  color: #000;
  padding: 5px 0px;
  font-size: 14px;
}
.header-dropdown-body ul li a i {
  margin-right: 5px;
}

.mobile-footer-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 9;
  box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, .14);
}
.mobile-footer-menu a {
  flex: 1;
  text-align: center;
  padding: 8px 6px;
  line-height: 1;
  color: #000;
  position: relative;
}
.mobile-footer-menu a i {
  display: block;
  font-size: 22px;
}
.mobile-footer-menu a span {
  font-size: 11px;
  display: block;
  margin-top: 5px;
  color: #7b7b7b;
  text-transform: capitalize;
  font-weight: 600;
}
.mobile-footer-menu a.active, .mobile-footer-menu a.active span
{
  color: var(--primary-color);
}
.lightbox .lb-image {
  border: none !important;
}
.order-detail-btn-right button.dropdown-toggle {
  border: solid 1px var(--primary-color);
}
.order-detail-btn-right button.dropdown-toggle::after
{
  display: none;
}
.order-detail-btn-right button.dropdown-toggle i {
  font-size: 16px;
}
.order-detail-btn-right ul.dropdown-menu {
  border: none;
  box-shadow: 0 10px 15px #dbdbdb;
  border-radius: 10px;
   overflow: hidden;
  min-width: max-content;
  padding: 6px;
  top: 10px !important;
  position: relative;
}
/* .order-detail-btn-right ul.dropdown-menu:after {
  background: white;
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  top: -6px;
  transform: rotate(45deg);
  right: 15px;
} */
.order-detail-btn-right ul.dropdown-menu a {
  color: #6c757d;
  font-size: 14px;
  padding: 5px 10px;
  display: block;
  border-radius: 7px;
}
.order-detail-btn-right ul.dropdown-menu a:hover {
  color: #000;
  background: #f7f9fb;
}
.order-detail-btn-right ul.dropdown-menu a i {
  margin-right: 5px;
}
.admin-version {
  color: #fff;
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: 300;
}
.admin-version span {
  font-weight: 500;
}
.gen-details-card {
  margin: 10px -15px 10px -15px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.gen-details-card strong, .gen-details-card small, .gen-details-info strong {
  display: block;
  font-size: 13px;
  width: 100%;
}
.pagesidebar-medium .gen-details-card li {
  flex: 0 0 33.33%;
  max-width: 33%;
}
.pagesidebar-medium .gen-details-card li a {
  font-weight: 500;
  color: var(--primary-color);
  text-decoration: underline;
  white-space: normal;
  word-wrap: break-word;
  font-size: 13px;
}
.gen-details-info-img {
  margin-bottom: 20px;
}
.gen-details-info-img img {
    max-height: 500px;
}
.gen-details-info-img img {
  border-radius: 10px;
}
.gen-details-card li {
  flex: 0 0 25%;
  padding: 5px 15px;
  max-width: 25%;
}
@media (max-width: 1400px) {
  .gen-details-card li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 992px) {
  .gen-details-card li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.menu-order-count {
  position: absolute;
  right: 8px;
  top: 8px;
  background: #e74833;
  padding: 3px 8px 1px 8px;
  line-height: normal;
  border-radius: 6px;
  text-align: center;
}


.page-form-filter .btn {
  padding: 8px 15px;
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
}

.media-table .table-list-img {
  width: 220px;
  height: auto;
}
.media-table .table-list-img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 140px;
}
.media-table td a {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 600;
}
.ae-font.ae-picker, .ae-font .ae-picker-label {
  border-radius: 10px;
}
.offcanvas .offcanvas-header a.offcanvas-close {
  color: #000;
  font-size: 25px;
  line-height: 0px;
  margin-left: 10px;
}
.page-filter-form {
  display: flex;
}
.page-filter-form .mini-filter-form-items {
  margin-left: 10px;
  position: relative;
}
.page-filter-form .mini-filter-form-items .form-control {
  font-size: 13px;
  min-height: 36px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px #d6dbf3;
  border: 1px solid #f1f1f1;
}
.form-select-arrow {
  position: relative;
}
.form-select-arrow:before {
  position: absolute;
  content: "";
  top: 8px;
  right: 10px;
  font-size: 14px;
  font-family: "Font Awesome 6 Pro";
}
.form-select-arrow .form-control {
  padding-right: 30px;
}

.btn-theme-mode {
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.btn-theme-mode i {
  position: relative;
  font-weight: 300;
  text-align: center;
}
.btn-theme-mode i:before {
  content: "";
  font-family: "Font Awesome 6 Pro";
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  left: -5px;
  position: absolute;
  top: 5px;
}
.btn-theme-mode:hover {
  background: #f4f6f9;
}
.dark-theme .btn-theme-mode i:before {
  content: "";
  left: -7px;
  color: #fff;
}
/* Dark Theme Start */

body.dark-theme .app-logo img {
  filter: brightness(0) invert(1);
}

body.dark-theme,
.dark-theme .mat-table,
.dark-theme .table-responsive,
.dark-theme .offcanvas,
.dark-theme .modal-content,
.dark-theme .order-detail-btn-right ul.dropdown-menu a:hover,
.dark-theme .header-dropdown-head-img,
.dark-theme .mobile-footer-menu a.active,
.dark-theme .btn-theme-mode:hover,
.dark-theme .offcanvas-footer-fix,
.dark-theme .offcanvas .offcanvas-header,
.dark-theme .upload-preview-ico .upload-preview-action a:hover  {
  background-color: var(--dark-primary-color) !important;
}

.dark-theme .sidebar,
.dark-theme header,
.dark-theme table.custom-mat-table tbody tr:nth-child(even),
.dark-theme .tab-btns-items.active,
.dark-theme .form-control,
.dark-theme .ng-select .ng-select-container,
.dark-theme .btn-upload-border,
.dark-theme .custom-modal .modal-header,
.dark-theme .accordion-button:not(.collapsed),
.dark-theme .small-media-items,
.dark-theme .order-detail-btn-left .order-detail-btn-pending-action,
.dark-theme div:where(.swal2-container) div:where(.swal2-popup),
.dark-theme .mobile-footer-menu,
.dark-theme .tab-counting,
.dark-theme .upload-preview-ico .upload-preview-action a,
.dark-theme .gen-entry-attachment-list-items,
.dark-theme .info-card-items,
.dark-theme span.mini-filter-count,
.dark-theme .product-report-table td.mat-cell:last-of-type .product-report-table-count
 {
  background-color: var(--dark-secondary-color) !important;
}

.dark-theme .angular-editor-toolbar {
  background: var(--dark-secondary-color) !important;
}

.dark-theme .dashboard-count-card-items,
.dark-theme header,
.dark-theme .header-dropdown,
.dark-theme .page-header-action-search input,
.dark-theme .page-filter-form .mini-filter-form-items .form-control,
.dark-theme .info-card-items {
  box-shadow: none;
}

.dark-theme .custom-mat-table thead th,
.dark-theme .page-header-action-items {
  background-color: #29394a;
}

.dark-theme .dashboard-count-card-items,
.dark-theme .mat-cell,
.dark-theme .mat-footer-cell,
.dark-theme .custom-mat-table thead th,
.dark-theme .table-action-btn .table-action-btn-view,
.dark-theme .table-action-btn .table-action-btn-edit,
.dark-theme .tab-btns-items,
.dark-theme .page-header-action-items,
.dark-theme .page-header-action-search input,
.dark-theme .page-header-action-search .btn-filter-search i,
.dark-theme .offcanvas .offcanvas-title,
.dark-theme .offcanvas .offcanvas-title p,
.dark-theme .product-details-right-info,
.dark-theme .content-box label,
.dark-theme .custom-modal label,
.dark-theme .custom-modal .modal-header h5,
.dark-theme .ng-select .ng-select-container,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme strong,
.dark-theme .summary-info .summary-info-card ul li span,
.dark-theme .order-detail-btn-left .order-detail-btn-pending-action,
.dark-theme .mat-paginator,
.dark-theme .mat-paginator-page-size .mat-select-trigger,
.dark-theme div:where(.swal2-container) div:where(.swal2-popup),
.dark-theme .mobile-footer-menu a,
.dark-theme .tab-counting,
.dark-theme .pagesidebar-mini label,
.dark-theme .pagesidebar-medium label,
.dark-theme .upload-preview-ico,
.dark-theme .upload-preview-ico:hover,
.dark-theme .upload-preview-ico .upload-preview-action a,
.dark-theme .info-cards-list .info-cards-list-items .info-cards-list-items-left i,
.dark-theme span.mini-filter-count,
.dark-theme .form-floating label
{
  color: rgba(233, 236, 239, .8);
}

.dark-theme .mat-paginator-range-actions svg {
  fill: rgba(233, 236, 239, .8);
}
.dark-theme .page-header-title,
.dark-theme p,
.dark-theme .sidebar-menu-btn,
.dark-theme .sidebar .sidebar-item .sidebar-item-button,
.dark-theme .header-r-side-btn>ul>li>a,
.dark-theme .prolist-price .d-flex,
.dark-theme .product-details-right-info h2,
.dark-theme .custom-mat-table td.mat-cell,
.dark-theme .custom-mat-table td,
.dark-theme .angular-editor-textarea,
.dark-theme .page-form-filter label,
.dark-theme .order-detail-btn-right ul.dropdown-menu a,
.dark-theme .dropdown-menu a,
.dark-theme .form-select-arrow:before,
.dark-theme .file-upload-details span,
.dark-theme .file-upload-details a,
.dark-theme .gen-details-card strong,
.dark-theme .summary-info-card .custom-mat-table th 
{
  color: rgba(233, 236, 239, .7);
}

.dark-theme .custom-mat-table td.mat-cell,
.dark-theme .custom-mat-table td,
.dark-theme .page-header-action-items,
.dark-theme .page-header-action-search input,
.dark-theme .order-detail-btn-left a,
.dark-theme .order-detail-btn-left button,
.dark-theme .offcanvas-footer-fix {
  border: none;
}


.dark-theme .gen-details-card small,
.dark-theme .product-details-right-info strong,
.dark-theme .product-details-right-info h3 span,
.dark-theme .form-control,
.dark-theme .btn-upload-border,
.dark-theme .modal-close,
.dark-theme .offcanvas .offcanvas-header a,
.dark-theme .accordion-button:not(.collapsed),
.dark-theme .static-form-control,
.dark-theme .mobile-footer-menu a.active,
.dark-theme .mobile-footer-menu a.active span,
.dark-theme .gen-details-info p a,
.dark-theme .pagesidebar-medium .gen-details-card li a,
.dark-theme .product-report-table td.mat-cell:last-of-type .product-report-table-count
 {
  color: #fff;
}

.dark-theme .page-header-action-search input {
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .accordion-button {
  background: transparent;
  color: #fff;
}

.dark-theme .accordion-button::after {
  background-color: rgba(255, 255, 255, 0.5);
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-position: center center;
}

.dark-theme .accordion .accordion-item,
.dark-theme .order-detail-btn-right ul.dropdown-menu,
.dark-theme .toast-container .ngx-toastr,
.dark-theme .toast-container .ngx-toastr:hover,
.dark-theme .tab-counting
 {
  box-shadow: 0 0 15px #29394a;
}

.dark-theme fieldset.border,
.dark-theme .accordion-item {
  background: transparent;
}

.dark-theme fieldset.border,
.dark-theme .angular-editor-textarea,
.dark-theme .static-form-control,
.dark-theme .order-detail-header-items,
.dark-theme .small-media-items,
.dark-theme .upload-preview-ico {
  border-color: #3f5266 !important;
}

.dark-theme fieldset .fieldset-header legend {
  color: #fff;
  background: #29394a !important;
  margin-bottom: 8px !important;
}

.dark-theme .product-detail-img-tab .nav-tabs .nav-link {
  border-color: transparent;
  background: var(--dark-secondary-color);
}

.dark-theme .product-detail-img-tab .nav-tabs .nav-link.active,
.dark-theme .form-control,
.dark-theme .ng-select .ng-select-container,
.dark-theme .btn-upload-border,
.dark-theme .header-dropdown-head,
.dark-theme .cmis-page-preview .cmis-page-preview-body .cmis-page-preview-items,
.dark-theme .tab-btns-items,
.dark-theme .file-upload-details,
.dark-theme .gen-entry-attachment-list-items,
.dark-theme span.mini-filter-count {
  border-color: #3f5266;
}

.dark-theme .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  box-shadow: none !important;
  background: transparent !important;
  color: rgba(233, 236, 239, .7);
}

.dark-theme .order-detail-btn-left a.active,
.dark-theme .order-detail-btn-left button.active {
  background: var(--primary-color);
  color: #fff;
  border: solid 1px var(--primary-color);
}

.dark-theme .order-detail-btn-left a,
.dark-theme .order-detail-btn-left button {
  color: #fff;
  background: #008000;
}

.dark-theme .btn-undelivered {
  font-weight: 700;
}

.dark-theme .dropdown-menu {
  background: var(--dark-secondary-color);
}

.dark-theme .ng-dropdown-panel {
  background: var(--dark-secondary-color);
  border-color: #3f5266;
  border-top: none;
}


.dark-theme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.dark-theme .cmis-page-preview-items .cmis-page-preview-items-action-info h3 {
  background: transparent;
  color: #fff;
}

.dark-theme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.dark-theme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background: var(--dark-primary-color);
  color: #fff;
}

.dark-theme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: var(--dark-primary-color);
  color: #fff;
}

.dark-theme .dropdown-menu .btn-default:hover {
  color: #000;
}

.dark-theme .loader-text,
.dark-theme .text-dark {
  color: rgba(233, 236, 239, .7) !important;
}

.dark-theme #loader i {
  color: var(--dark-secondary-color) !important;
}

.dark-theme .loader-text::before {
  color: var(--dark-secondary-color) !important;
}

.dark-theme .page-login-register-right-form .form-control {
  border: 1px solid #e2e2e2;
  background: #fff;
  color: #000;
}
.dark-theme .swal2-container .swal2-popup .swal2-icon,
.dark-theme .swal2-container .swal2-popup .swal2-title {
  color: rgba(233, 236, 239, .7) !important;
}

.dark-theme .modal table td .btn-border {
  background: transparent;
  color: #fff;
}
.dark-theme .form-control:focus, .dark-theme .form-select:focus {
  border-color: rgba(233, 236, 239, .7)
}
.dark-theme .form-control.is-invalid, .dark-theme .was-validated .form-control:invalid
{
border-color: #dc3545 ;
}
.dark-theme .invalid-feedback strong
{
  color: #dc3545 ;
}
.dark-theme .angular-editor-textarea.disabled
{
background: transparent !important;
}
.dark-theme table.mat-table {
  border: solid 1px var(--dark-secondary-color);
}
.dark-theme .page-header-action .form-control, 
.dark-theme .page-header-action .form-select,
.dark-theme .offcanvas .offcanvas-header select.form-select {
  font-size: 13px;
  min-height: 36px;
  border: 1px solid #f1f1f1;
  background-color: #19222c;
  color: rgba(233, 236, 239, .5);
  border-color: #303942;
}
.dark-theme .ae-font .ae-picker-label {
  background: transparent;
  color: #fff;
  border-color: #3f5266;
}
.dark-theme .ae-font .ae-picker-label:before {
  background: #19222c;
}
/* Dark Theme End */

.dashboard-count-card-items .dashboard-count-card-items-info h1 span {
  font-size: 22px;
  line-height: normal;
}
.tab-counting {
  padding: 1px 5px;
  text-align: center;
  line-height: normal;
  margin-left: 2px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 1px 4px #d6dbf3;
  color: #8d8d8d;
  /* position: absolute; */
  /* right: -9px; */
  top: -9px;
  font-size: 12px;
  font-weight: 600;
  min-width: 25px;
  display: inline-block;
}
.tab-btns-items.active .tab-counting {
  background: var(--primary-color);
  color: #fff;
}
.pagesidebar-mini {
  max-width: 500px;
}
.pagesidebar-medium {
  max-width: 55%;
}
.pagesidebar-mini label, .pagesidebar-medium label {
  font-size: 12px;
  display: block;
  margin-bottom: 2px;
}
.pagesidebar-mini .form-floating label, .pagesidebar-medium .form-floating label, .page-setting .form-floating label {
  font-size: 14px;
  margin-bottom: 0px;
  top: 3px;
}
.pagesidebar-mini .form-floating>.form-control:not(:placeholder-shown)~label, .pagesidebar-medium .form-floating>.form-control:not(:placeholder-shown)~label
{
  top: 0px;
}
.pagesidebar-mini .angular-editor-toolbar, .pagesidebar-medium .angular-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.pagesidebar-mini .angular-editor-toolbar-set, .pagesidebar-medium .angular-editor-toolbar-set {
    display: flex !important;
}
.offcanvas-footer-fix {
  position: fixed;
  background: #fff;
  width: 100%;
  left: auto;
  max-width: 500px;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid #f5f5f5;
  text-align: right;
}
.pagesidebar-medium .offcanvas-footer-fix {
  max-width: 55%;
}
.offcanvas-footer-fix .btn.btn-default {
  margin-right: 10px;
}
.offcanvas-body-fix-scroll
{
  padding-bottom: 50px;
}
.tab-btns a.tab-btns-items:first-child {
  margin-left: 0px;
}


span.info-tooltip {
  position: relative;
  margin-left: 5px;
}

span.info-tooltip:hover .info-tooltip-hover {
  display: block;
}
.info-tooltip-hover {
  background: var(--primary-color);
  position: absolute;
  z-index: 99;
  color: #fff;
  width: 100%;
  min-width: 165px;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  display: none;
  top: 25px;
}
.info-tooltip-hover:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  top: -4px;
  left: 48px;
  background: var(--primary-color);
  transform: rotate(45deg);
}

.table-data-tags a span {
  background: transparent;
  display: inline-block;
  border: solid 1px #e9e9e9;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);
  border-radius: 30px;
  margin: 3px;
  cursor: pointer;
}
.table-data-tags a span.active {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.dark-theme .table-data-tags a span
{
border-color: #29394a;
color: rgba(233, 236, 239, .8);
}
.dark-theme  .checkbox-card
{
  border-color: #29394a;
color: rgba(233, 236, 239, .8);
}
.upload-preview-ico {
  height: 75px;
  width: 75px;
  border: solid 1px #f1f1f1;
  background: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
}
.upload-preview-ico:hover {
  color: #000;
}
.upload-preview-ico .upload-preview-ico-icon {
  font-size: 40px;
  line-height: 0;
}
.upload-preview-ico .upload-preview-action {
  position: absolute;
  line-height: 0;
  left: 50%;
  top: -10px;
  display: flex;
  transform: translateX(-50%);
}
.upload-preview-ico .upload-preview-action a {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e7e7;
  border-radius: 100%;
  font-size: 13px;
  color: #000000;
  margin: 0px 2px;
}
.upload-preview-ico .upload-preview-action a:hover {
  background: #d3d3d3;
}
.media-table td {
  vertical-align: top;
}
.gen-entry-attachment-list-items {
  height: 150px;
  width: 150px;
  border: solid 1px #f1f1f1;
  margin: 0px 5px 0px 0px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}
.gen-entry-attachment-list-items img {
  max-height: 150px;
}
.gen-entry-attachment-list {
  display: flex;
  flex-wrap: wrap;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option
{
  font-size: 13px;
  padding: 6px 10px;
}
.master-form-auto {
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-wrap: wrap;
}

.master-form-auto .master-form-auto-col {
  padding: 0px 15px;
  flex: 1 1 50%;
}
span.page-item-count {
  background: var(--primary-color);
  color: #fff;
  padding: 2px 8px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: -2px;
}
.filter-datepicker {
  position: relative;
}
.filter-datepicker:before {
  content: "";
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  font-size: 16px;
  left: 10px;
  top: 9px;
  z-index: 99;
  color: #adadad;
  line-height: normal;
}

.filter-datepicker .form-select
{
  padding-left: 30px;
}
.info-card-items {
  box-shadow: 0 2px 6px #d9dff7;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  height: 100%;
}
.info-card-items .info-card-items-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.info-card-items .info-card-items-header h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  text-transform: capitalize;
}
.info-card-items .info-card-items-header h2 {
  font-size: 18px;
  margin: 0px;
}
.info-card-items .info-card-items-header h2 span {
  font-size: 14px;
}
.charts .info-card-items .info-card-items-body {
  text-align: center;
}
.info-card-items .info-card-items-body img {
  margin: auto;
  max-width: 250px;
  width: 100%;
}
.info-cards-list .info-cards-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  color: #5e5e5e;
  padding: 8px 0px;
  margin: 4px 0px;
}
.info-cards-list .info-cards-list-items:hover, .info-cards-list .info-cards-list-items:hover h3, .info-cards-list .info-cards-list-items:hover h4
{
  color: var(--primary-color);
}

.info-cards-list .info-cards-list-items .info-cards-list-items-left {
  display: flex;
  align-items: center;
  flex: auto;
}
.info-cards-list .info-cards-list-items .info-cards-list-items-left i {
  font-size: 22px;
  margin-right: 8px;
}
/* .info-cards-list .info-cards-list-items .info-cards-list-items-left h3 {
  font-size: 16px;
}
.info-cards-list .info-cards-list-items h4 {
  font-size: 16px;
} */
.info-cards-list .info-cards-list-items h3, .info-cards-list .info-cards-list-items h4 {
  margin: 0px;
  font-size: 16px;
}
span.mini-filter-count {
  position: absolute;
  background: #fff;
  color: #000;
  padding: 0px 5px;
  border-radius: 20px;
  font-weight: 600;
  right: 0px;
  top: -9px;
  font-size: 13px;
  border: 1px solid #dfdfdf;
  line-height: normal;
  min-width: 28px;
  text-align: center;
}
.page-dashboard .row {
  margin: 0px -10px;
}

/* DatePicker Start */

.date-picker-icon i {
  position: absolute;
  right: 12px;
  top: 9px;
  color: #878787;
  font-size: 16px;
}
.bs-datepicker {
  flex-direction: row-reverse;
}
.date-picker-icon input.form-control {
  padding-right: 35px;
  min-width: 222px;
}
.bs-datepicker {
  box-shadow: 0 2px 6px #d9dff7;
  border-radius: 15px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .bs-datepicker {
    right: 190px;
  }
}
.bs-datepicker-predefined-btns button {
  background: #fff;
  color: #000;
  height: auto;
  padding: 3px 15px;
  font-size: 13px;
  border-radius: 20px;
  font-weight: 500;
}
.bs-datepicker-custom-range {
  background: #ffffff;
  border-right: solid 2px #f3f3f3;
}
.theme-default .bs-datepicker-predefined-btns button.selected, .bs-datepicker-predefined-btns button:hover {
  background-color: #f1f1f1;
  color: #000000;
}
.theme-default .bs-datepicker-body table td span.selected, 
.theme-default .bs-datepicker-body table td.selected span, 
.theme-default .bs-datepicker-body table td span[class*=select-]:after, 
.theme-default .bs-datepicker-body table td[class*=select-] span:after,
.theme-default .bs-datepicker-head
{
  background-color: var(--primary-color);
}
.theme-default .bs-datepicker-body table td span.selected:hover {
  color: #fff;
}
.bs-datepicker-head {
  height: 38px;
  padding: 4px;
  border-radius: 30px;
  margin: 0px 5px 10px 5px;
}
.bs-datepicker .bs-media-container {
  border-radius: 10px;
  overflow: hidden;
}
.bs-datepicker-body {
  border: 1px solid #f7f7f7;
  border-radius: 0px;
  padding-top: 5px;
}
.bs-datepicker-body table th {
  height: 32px;
}


@media (max-width: 768px) {
  .bs-datepicker {
      width: 100% !important;
  }
  bs-daterangepicker-container {
    transform: translate3d(0px, 151px, 0px) !important;
}
}

/* DatePicker End */

.product-report-table thead th:last-child {
  width: 80px;
}
.product-report-table thead th:last-child .mat-sort-header-container {
  justify-content: center;
}
.product-report-table td.mat-cell:last-of-type .product-report-table-count {
  background: #e7e7e7;
  font-size: 17px;
  text-align: center;
  border-radius: 20px;
  max-width: 49px;
  font-weight: 700;
}
.summary-info .summary-info-card ul {
  list-style: none;
}
.summary-info .summary-info-card ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 5px 0px;
  color: var(--primary-color);
}
.summary-info-card .custom-mat-table td, .summary-info-card .custom-mat-table th {
  padding: 6px 0px !important;
  font-size: 14px;
  position: relative;
}
.summary-info-card table.custom-mat-table {
  margin: 5px 0px;
}
/* .summary-info-card table.custom-mat-table tr td:first-child:before {
  font-family: "Font Awesome 6 Pro";
  margin-right: 5px;
}
.summary-info-card table.custom-mat-table tr:nth-child(2) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(3) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(4) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(5) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(6) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(7) td:first-child:before {
  content: "";
}
.summary-info-card table.custom-mat-table tr:nth-child(8) td:first-child:before {
  content: "";
} */
.page-setting .btn-upload-border {
  min-height: 50px;
  font-size: 14px;
  font-weight: 500;
}
.row.page-setting-card {
  max-width: 800px;
  margin: auto;
  background: #fff;
  padding: 20px 5px;
  border-radius: 10px;
}
.page-setting .tab-btns-items {
  padding: 3px 15px;
}

.page-setting .tab-btns-items.active {
  background: var(--primary-color) !important;
  color: #fff;
  border-color: var(--primary-color);
}
.page-setting-card .setting-file-upload {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.page-setting-card .setting-file-items {
  position: relative;
  margin-bottom: 0px;
  flex: 1 1 45%;
}

.page-setting-card .setting-file-items .small-media-items {
  position: absolute !important;
  right: 12px;
  z-index: 99;
  bottom: 6px;
  height: 50px;
  width: 55px;
  margin: 0px;
  border: none;
  background: transparent;
}
.row.page-setting-card textarea.form-control {
  min-height: 120px;
}
.page-setting-card .setting-file-items .invalid-feedback {
  margin-top: -3px;
}
.page-setting-card .setting-file-items .small-media-items-img {
  height: auto;
  width: auto;
}
.page-setting-card .setting-file-items .small-media-items-img img {
  max-width: 42px;
  max-height: 42px;
}
.page-setting .btn-upload-border {
  font-size: 12px;
  color: #626262;
  text-align: left;
}
.page-setting-card .setting-file-items label {
  font-size: 13px;
  margin-bottom: 3px;
}
.show-kimayra
{
display: none;
}



.fix-btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  background: #000;
  border-radius: 100%;
  color: #fff;
  font-size: 25px;
  position: fixed;
  right: 50px;
  bottom: 60px;
}