
/* @media all and (min-width: 992px) {
.dropdown:hover .dropdown-menu {
  display: block;
}
} */



@media (max-width: 1550px) {
.dashboard-count-card-items .dashboard-count-card-items-info h1 {
  font-size: 30px;
}
.dashboard-count-card-items .dashboard-count-card-items-info p {
  font-size: 14px;
}

}

@media (max-width: 1392px) {
  .cmis-page-create-from {
    flex: 1 1 100%;
  }

  .cmis-page-preview {
    flex: 1 1 100%;
    padding-left: 0px;
    margin-top: 25px;
  }

  .cmis-page-preview-body {
    max-height: initial;
  }
  .pagesidebar-medium {
    max-width: 75%;
}

}

@media (min-width: 1192px) {
  .sidebar {
    height: calc(100% - 75px);
    margin-top: 65px;
    left: 10px;
    border-radius: 20px;
}
.sidebar-height {
  height: 100%;
}
/* New Sidebar Start */
.sidebar-mini .sidebar {
  width: 65px;
  border-radius: 15px;
}
.sidebar-mini .sidebar span.menu-name {
  display: none;
}
.sidebar-mini .sidebar .sidebar-item-button::after
{
  display: none;
}
.sidebar-mini .container-with-sidebar {
  margin-left: 60px;
} 
.sidebar-mini ul.sidebar-submenu {
  display: none;
}
.sidebar-mini .sidebar-footer
{
  display: none;
}
.sidebar-mini .menu-order-count {
  right: 3px;
  top: -1px;
  padding: 1px 6px;
  border-radius: 6px;
  font-size: 13px;
}

/* .sidebar-hover .sidebar
{
width: 205px;
}
.sidebar-hover .sidebar span.menu-name
{
  display: inline-block;
}
.sidebar-hover .sidebar .sidebar-item-button::after
{
  display: inline-block;
}
.sidebar-hover .container-with-sidebar {
  margin-left: 200px;
} 
.sidebar-hover ul.sidebar-submenu, .sidebar-hover .sidebar-footer {
  display: block;
}
.sidebar-hover .menu-order-count {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 1px 8px;
  border-radius: 8px;
  font-size: 15px;
} */
/* New Sidebar End */

}
@media (max-width: 1192px) {
  .product-detail-img-tab .nav-item {
    flex: 0 0 70px;
    max-width: 70px;
  }

  .product-detail-img-tab .nav-item img {
    max-height: 70px;
  }
 .sidebar {
    transform: translatex(-250px);
  }
  .container-with-sidebar
  {
    margin-left: 0px;
    padding: 70px 15px 0px 15px;
  }
   body.sidebar-open .sidebar {
    transform: none;
  }
  body.sidebar-open .container-with-sidebar, body.sidebar-open footer {
    margin-left: 0px;
}
footer {
  margin-left: 0px;
}
.single-detail-img {
  flex: 100%;
  margin-bottom: 15px;
}
.gen-details-info {
  flex: 100%;
 
}
}

@media (min-width: 1110px) {
  .pagesidebar-medium .pagesidebar-medium-col-50 {
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .default-section-info h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .no-record-found p {
    font-size: 18px;
  }

  .cdk-overlay-pane {
    max-width: 95% !important;
  }

  .product-details-right-info h2 {
    font-size: 20px;
  }

  .product-detail-img-tab .nav-item {
    flex: 0 0 50px;
    max-width: 50px;
  }

  .product-detail-img-tab .nav-item img {
    max-height: 50px;
  }

  .file-upload-details .dz-details .dz-filename {
    max-width: 160px;
  }
  .page-header {
    display: block;
    text-align: center;
}
.page-header-action {
  margin-top: 10px;
  justify-content: center;
}
.page-header-action-items {
  margin-bottom: 15px;
}
.page-header-action-search {
  margin-left: 0px;
  min-width: 300px;
  width: 100%;
}
section.page-login-register .row
{
height: auto !important;
}
section.page-login-register {
  background: transparent;
}
.page-login-register-right:before
{
  display: none;
}
.page-login-register-right-form {
  box-shadow: 0 0 20px #d7dced;
  margin-top: 30px;
  padding: 15px;
}
.login-register-logo img {
  max-height: 65px;
}
.page-login-register-right-form h2 {
  font-size: 25px;
  margin-bottom: 25px;
}
.offcanvas {
  max-width: 100%;
}
.login-register-logo {
  display: block;
  text-align: center;
}
.order-detail-header .order-detail-header-items:first-child
{
  padding-left: 12px;
}
.order-detail-header-items {
  padding: 0px 12px;
  flex: 1 1 50%;
  border: none;
  margin-bottom: 10px;
}
.order-detail-header-items h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
.order-detail-header-items p {
  font-size: 13px;
}
.order-detail-header .order-detail-header-items:last-child {
  flex: 1 1 100%;
}
.tab-btns {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}
.tab-btns-items {
  padding: 5px 15px;
  font-size: 15px;
  margin: 0px 6px;
}
.order-detail-btn-right {
  flex: auto;
  justify-content: center;
  margin: 5px 0px;
}
.order-detail-btn-right [data-title]:hover::before
{
  left: 50%;
  transform: translateX(-50%);
}
.order-detail-btn-left a, .order-detail-btn-left button {
  flex: 1;
  text-align: center;
  margin: 4px;
}
.offcanvas .offcanvas-body {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-menu-btn {
  margin-left: 0px;
  order: -1;
  margin-right: 10px;
}
.header-dropdown {
  min-width: 275px;
  padding: 12px;
}
.page-header-title {
  font-size: 22px;
}
.order-detail-btn-right .dropdown {
  margin-top: 10px;
}
.offcanvas .offcanvas-body .content-box {
  padding: 0px 15px 15px 15px;
}
body, html
{
  height: auto;
}
body {
  padding-bottom: 50px;
}
.page-filter-form {
  margin: 0px 0px 10px 0px;
}
.offcanvas-footer-fix, .pagesidebar-medium .offcanvas-footer-fix {
  max-width: 100%;
}
.table-action-btn {
  min-width: 100px;
}
}

@media (max-width: 595px) {
  header {
    padding: 5px 6px;
}
.app-logo img {
  max-height: 35px;
}
.container-with-sidebar
{
  padding-bottom: 20px;
}
span.login-name {
  font-size: 12px;
  max-width: 80px;
}
.custom-modal .modal-header {
  padding: 8px 8px 8px 15px;
}
.master-form-auto .master-form-auto-col {
  flex: 1 1 100%;
}
.summary-info .summary-info-card ul li {
  font-size: 14px;
  padding: 4px 0px;
}
.summary-info .summary-info-card ul li:last-child {
  font-size: 16px;
}
.summary-info-card .custom-mat-table td, .summary-info-card .custom-mat-table th {
  font-size: 12px;
}
#FinancesSummary .offcanvas-header {
  position: relative;
}
#FinancesSummary .offcanvas-header .offcanvas-close {
  position: absolute;
  top: 15px;
  right: 15px;
}
.page-setting-card .setting-file-upload {
  gap: 8px;
}
.page-setting-card .setting-file-items {
  flex: 100%;
}
}

/* Dark Theme Start */
@media (max-width: 767px) {
  .dark-theme .page-login-register-right-form
  {
    background: var(--dark-secondary-color);
    box-shadow: 0 0 15px #29394a;
  }
  .dark-theme .page-login-register-right-form h2 {
    color: #fff !important;
}
.dashboard-count-card-items .dashboard-count-card-items-info h1 {
  font-size: 32px;
}
.order-counting {
  position: static;
}
.mobile-row-m-0
{
  margin: 0px;
}
.pagesidebar-mini .angular-editor-toolbar, .pagesidebar-medium .angular-editor-toolbar {
  flex-wrap: nowrap;
}
.with-filter-sidebar .offcanvas-header {
  flex-wrap: wrap;
}
.with-filter-sidebar .offcanvas-header .page-header-action {
  flex: 100%;
  justify-content: space-between;
  margin-top: 15px;
}
.with-filter-sidebar .offcanvas-header .page-header-action .page-filter-form {
  margin: 0px;
}
}
/* Dark Theme End */
